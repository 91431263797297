import axios from "axios";
import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import makeAnimated from "react-select/animated"; // Import animasi untuk dropdown
import "../../custom.css";

const TabInputSewa = ({ handleTabClick }) => {
  const [events, setEvents] = useState([]);
  const [eventId, setEventId] = useState("");
  const [name, setName] = useState("");
  const [category, setCategory] = useState("");
  const [vendor, setVendor] = useState("");
  const [harga, setHarga] = useState("");
  const [quantity, setQuantity] = useState("");
  const [periodTime, setPeriodTime] = useState("");
  const [periodeDay, setPeriodeDay] = useState("");
  const [selectedEvent, setSelectedEvent] = useState(null);
  // State for message and notification
  const [msg, setMsg] = useState("");
  const [showNotification, setShowNotification] = useState(false);
  const [showErrorNotification, setShowErrorNotification] = useState(false);

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: "hsl(221, 14%, calc(9% + 0%))", // Warna latar belakang
      borderColor: "hsl(221, 14%, calc(24% + 0%))", // Warna border
      borderRadius: "var(--bulma-input-radius)", // Border radius dari Bulma
      color: "white", // Warna teks menggunakan variabel Bulma

      // Optional: Anda bisa menyesuaikan border ketika fokus
      border: state.isFocused ? "1px solid hsl(221, 14%, calc(24% + 0%))" : "1px solid hsl(221, 14%, calc(24% + 0%))",
      boxShadow: state.isFocused ? "0 0 0 2px rgba(100, 150, 250, 0.5)" : null,

      "&:hover": {
        border: "1px solid hsl(221, 14%, calc(24% + 0%))", // Warna border saat hover
      },
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "hsl(221, 14%, calc(9% + 0%))", // Background untuk menu dropdown
      color: "white",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "hsl(221, 14%, calc(9% + 0%))" : state.isFocused ? "#222928" : "hsl(221, 14%, calc(9% + 0%))", // Background opsi
      color: "#fff",
      "&:hover": {
        backgroundColor: "#222928",
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#fff", // Warna teks item yang dipilih
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#aaa", // Warna teks placeholder
    }),
    input: (provided) => ({
      ...provided,
      color: "#fff", // Warna teks saat mengetik
    }),
  };

  useEffect(() => {
    // Fetch existing events
    const getEventsDropdown = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/dropdownevents`); // Adjust the API endpoint
        setEvents(response.data);
      } catch (err) {
        console.error(err);
      }
    };

    getEventsDropdown();
  }, []);

  const saveItem = async (e) => {
    e.preventDefault();
    if (!name || !vendor || !harga || !quantity || !periodTime | !periodeDay) {
      setMsg("Lengkapi semua field untuk menambahkan item");
      setShowErrorNotification(true);
      setTimeout(() => {
        setShowErrorNotification(false);
      }, 2500);
      return;
    }
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/sewa`, {
        eventId: selectedEvent.value,
        name: name,
        category: category,
        vendor: vendor,
        harga: parseInt(unformatRupiah(harga)),
        quantity: quantity,
        periodTime: periodTime,
        periodeDay: periodeDay,
      });
      setSelectedEvent("");
      setName("");
      setCategory("");
      setVendor("");
      setHarga("");
      setQuantity("");
      setPeriodTime("");
      setPeriodeDay("");

      // Show notification
      setMsg("Item berhasil ditambahkan.");
      setShowNotification(true);
      setTimeout(() => {
        setShowNotification(false);
      }, 2500);
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg);
      }
    }
  };

  const handleCloseNotification = () => {
    setShowNotification(false);
    setShowErrorNotification(false);
  };

  const handleEventChange = (selectedOption) => {
    setSelectedEvent(selectedOption);
  };

  // Fungsi untuk memformat angka menjadi format Rupiah
  const formatRupiah = (number) => {
    if (!number) return "";
    return "Rp " + number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  };

  // Fungsi untuk menghapus format Rupiah sebelum dikirim ke backend
  const unformatRupiah = (number) => {
    return number.replace(/[^,\d]/g, ""); // Hanya menyisakan angka
  };

  // Fungsi untuk menangani perubahan input dan langsung memformat ke Rupiah
  const handlePriceChange = (e) => {
    const value = e.target.value;
    setHarga(formatRupiah(unformatRupiah(value))); // Set dengan format Rupiah
  };

  return (
    <div>
      <br />
      <h4 className="title is-4 has-text-centered mb-0">Input Barang Sewa dari Vendor</h4>
      {showNotification && (
        <div className="notification-container">
          <div className="notification is-info is-light">
            <button className="delete" onClick={handleCloseNotification}></button>
            {msg}
          </div>
        </div>
      )}
      {showErrorNotification && (
        <div className="notification-container">
          <div className="notification is-danger is-light">
            <button className="delete" onClick={handleCloseNotification}></button>
            {msg}
          </div>
        </div>
      )}
      <div className="card is-shadowless has-color-grey ml-6 mr-6">
        <div className="card-content has-background-dark pt-0">
          <div className="content">
            {/* Outer form to handle checklist saving */}
            <form onSubmit={saveItem}>
              {/* <p className="has-text-centered">{msg}</p> */}
              <br />
              <div className="field">
                <label className="label is-size-6 has-text-grey-light">Event</label>
                <div className="control">
                  {/* Dropdown menggunakan react-select */}
                  <Select
                    styles={customStyles}
                    className="has-text-black"
                    value={selectedEvent}
                    onChange={handleEventChange} // Fungsi untuk menangani perubahan
                    options={events} // Data event dari API
                    placeholder="Pilih atau cari event"
                    isClearable
                    isSearchable
                    components={makeAnimated()} // Optional: Untuk animasi dropdown
                  />
                </div>
              </div>
              <div className="field">
                <label className="label is-size-6 has-text-grey-light">Nama Barang</label>
                <div className="control">
                  <input type="text" className="input" value={name} onChange={(e) => setName(e.target.value)} placeholder="Masukkan nama barang" />
                </div>
              </div>

              <div className="field">
                <label className="label is-size-6 has-text-grey-light">Category</label>
                <div className="control">
                  <input type="text" className="input" value={category} onChange={(e) => setCategory(e.target.value)} placeholder="Masukkan category barang" />
                </div>
              </div>

              <div className="field">
                <label className="label is-size-6 has-text-grey-light">Vendor</label>
                <div className="control">
                  <input type="text" className="input" value={vendor} onChange={(e) => setVendor(e.target.value)} placeholder="Masukkan nama vendor" />
                </div>
              </div>

              <div className="field">
                <label className="label is-size-6 has-text-grey-light">Harga</label>
                <div className="control">
                  <input type="text" className="input" value={harga} onChange={handlePriceChange} placeholder="Masukkan harga" />
                </div>
              </div>

              <div className="field">
                <label className="label is-size-6 has-text-grey-light">Quantity</label>
                <div className="control">
                  <input type="number" className="input" value={quantity} onChange={(e) => setQuantity(e.target.value)} placeholder="Masukkan jumlah quantity" min="0" />
                </div>
              </div>

              <div className="field">
                <label className="label is-size-6 has-text-grey-light">Periode Time</label>
                <div className="control">
                  <input type="text" className="input" value={periodTime} onChange={(e) => setPeriodTime(e.target.value)} placeholder="Masukkan periode (time)" />
                </div>
              </div>

              <div className="field">
                <label className="label is-size-6 has-text-grey-light">Periode Day</label>
                <div className="control">
                  <input type="text" className="input" value={periodeDay} onChange={(e) => setPeriodeDay(e.target.value)} placeholder="Masukkan periode (day)" />
                </div>
              </div>

              <div class="columns mt-5">
                <div class="column is-one-fifth"></div>
                <div class="column">
                  <button type="submit" className="button is-success is-fullwidth disabled={loading}">
                    Simpan
                  </button>
                </div>
                <div class="column is-one-fifth"></div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TabInputSewa;
