import { format } from "date-fns";
import id from "date-fns/locale/id"; // Import locale bahasa Indonesia
import React from "react";
import { IoAdd } from "react-icons/io5";

const TabSuratJalan = ({ suratJalan, onSuratJalanClick, onSuratJalanAdd }) => {
  return (
    <div className="columns mr-2 ml-2 mt-10">
      <div className="column is-four-fifths">
        {suratJalan.length === 0 ? (
          <p>Beum ada surat jalan untuk event ini</p>
        ) : (
          suratJalan.map((suratJalanItem, index) => (
            <div className="box" key={suratJalanItem.id}>
              <div className="mb" style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <div>
                  <h5 className="title is-5">Surat Jalan {index + 1}</h5>
                  <h4 className="subtitle is-6 mt-3">
                    Job No: {suratJalanItem.jobNo} | Dibuat pada tanggal {format(new Date(suratJalanItem.date), "d MMMM yyyy", { locale: id })}
                  </h4>
                </div>
                <button className="button is-secondary mr-2" onClick={() => onSuratJalanClick(suratJalanItem, index)}>
                  Buka
                </button>
              </div>
            </div>
          ))
        )}
      </div>
      <div className="column has-text-centered mt-2">
        <button className="button is-primary " onClick={() => onSuratJalanAdd()}>
          <IoAdd className="mr-2" /> Tambah Surat Jalan
        </button>
        {/* <NavLink to={"/events/test"}>Dashboard</NavLink> */}
      </div>
    </div>
  );
};

export default TabSuratJalan;
