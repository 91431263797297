import axios from "axios";
import { format } from "date-fns";
import id from "date-fns/locale/id"; // Import locale bahasa Indonesia
import React, { useEffect, useState } from "react";
import { IoArrowBackCircleOutline, IoCalendarOutline, IoPin } from "react-icons/io5";
import { Link, useLocation, useParams } from "react-router-dom";
import "../../custom.css";
import AddChecklistForm from "./AddChecklistForm";
import AddSuratJalanForm from "./AddSuratJalanForm";
import TabChecklist from "./TabChecklist";
import TabChecklistDetail from "./TabChecklistDetail";
import TabMaster from "./TabMaster";
import TabMasterClient from "./TabMasterClient";
import TabMasterInternal from "./TabMasterInternal";
import TabPengembalian from "./TabPengembalian";
import TabSewa from "./TabSewa";
import TabSuratJalan from "./TabSuratJalan";
import TabSuratJalanDetail from "./TabSuratJalanDetail";

const EventContent = () => {
  const { eventId } = useParams();
  const [checklist, setChecklist] = useState([]);
  const [suratJalan, setSuratJalan] = useState([]);
  const [itemSewa, setItemSewa] = useState([]);
  const [activeTab, setActiveTab] = useState("master");
  const [loading, setLoading] = useState(false);
  const [selectedChecklist, setSelectedChecklist] = useState(null);
  const [selectedSuratJalan, setSelectedSuratJalan] = useState(null);
  const [checklistItems, setChecklistItems] = useState(null);
  const [suratJalanItems, setSuratJalanItems] = useState(null);
  const [checklistTitle, setChecklistTitle] = useState(""); // State for title
  const [suratJalanTitle, setSuratJalanTitle] = useState(""); // State for title

  const location = useLocation();
  const { event } = location.state || {}; // Ambil event dari state

  const getChecklist = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/events/${eventId}/checklists`);
      setChecklist(response.data);
      const items = response.data.flatMap((checklist) => checklist.checklist_items);
      setChecklistItems(items);
    } catch (error) {
      console.error("Failed to fetch work items", error);
    } finally {
      setLoading(false);
    } // Set loading ke false setelah fetch
  };

  const getSuratJalan = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/events/${eventId}/suratjalan`);
      setSuratJalan(response.data);
      const items = response.data.flatMap((suratJalan) => suratJalan.surat_jalan_items);
      setSuratJalanItems(items);
    } catch (error) {
      console.error("Failed to fetch work items", error);
    } finally {
      setLoading(false);
    } // Set loading ke false setelah fetch
  };
  const getItemSewa = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/sewa/${eventId}`);
      setItemSewa(response.data);
    } catch (error) {
      console.error("Failed to fetch work items", error);
    } finally {
      setLoading(false);
    } // Set loading ke false setelah fetch
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab); // Change the active tab when clicked
  };

  // const handleChecklist = () => {
  //   setActiveTab("checklist"); // To redirect
  // };

  const handleChecklistClick = (checklistItem, index) => {
    setSelectedChecklist(checklistItem);
    setActiveTab("checklistDetails"); // Change tab to show details
    setChecklistTitle(`Checklist ${index + 1}`); // Set title
  };

  const handleSuratJalanClick = (suratJalanItem, index) => {
    setSelectedSuratJalan(suratJalanItem);
    setActiveTab("suratJalanDetails"); // Change tab to show details
    setSuratJalanTitle(`Surat Jalan ${index + 1}`); // Set title
  };

  const handleChecklistAdd = () => {
    setActiveTab("addChecklist"); // Change tab to show details
  };
  const handleSuratJalanAdd = () => {
    setActiveTab("addSuratJalan"); // Change tab to show details
  };
  const handleMasterClient = () => {
    setActiveTab("masterClient"); // Change tab to show details
  };
  const handleMasterInternal = () => {
    setActiveTab("masterInternal"); // Change tab to show details
  };
  const handleBackMaster = () => {
    setActiveTab("master"); // Change tab to show details
  };

  const renderActiveTab = () => {
    if (selectedChecklist && activeTab === "checklistDetails") {
      return <TabChecklistDetail selectedChecklist={selectedChecklist} checklist={checklist} title={checklistTitle} />;
    }
    if (selectedSuratJalan && activeTab === "suratJalanDetails") {
      return <TabSuratJalanDetail selectedSuratJalan={selectedSuratJalan} checklist={checklist} title={suratJalanTitle} />;
    }
    if (activeTab === "addChecklist") {
      return <AddChecklistForm handleTabClick={handleTabClick} />;
    }
    if (activeTab === "addSuratJalan") {
      return <AddSuratJalanForm handleTabClick={handleTabClick} />;
    }
    if (activeTab === "masterClient") {
      return <TabMasterClient suratJalanItems={suratJalanItems} handleMasterInternal={handleMasterInternal} handleBackMaster={handleBackMaster} itemSewa={itemSewa} />;
    }
    if (activeTab === "masterInternal") {
      return <TabMasterInternal checklistItems={checklistItems} handleMasterClient={handleMasterClient} handleBackMaster={handleBackMaster} />;
    }

    switch (activeTab) {
      case "master":
        return <TabMaster handleMasterClient={handleMasterClient} handleMasterInternal={handleMasterInternal} />;
      case "suratjalan":
        return <TabSuratJalan suratJalan={suratJalan} onSuratJalanClick={handleSuratJalanClick} onSuratJalanAdd={handleSuratJalanAdd} />;
      case "checklist":
        return <TabChecklist checklist={checklist} onChecklistClick={handleChecklistClick} onChecklistAdd={handleChecklistAdd} />;
      case "sewa":
        return <TabSewa itemSewa={itemSewa} />;
      case "pengembalian":
        return <TabPengembalian checklistItems={checklistItems} isPengembalianEditable={event.isPengembalianEditable} eventId={eventId} />;
      default:
        return <p>Pilih sebuah tab.</p>;
    }
  };

  useEffect(() => {
    setLoading(true); // Set loading to true saat memulai fetch
    console.log("Active tab Use Effect:", activeTab);
    if (activeTab === "masterClient") {
      getSuratJalan();
      getItemSewa();
    } else if (activeTab === "suratjalan") {
      getSuratJalan();
    } else if (activeTab === "sewa") {
      getItemSewa();
    } else {
      getChecklist();
    }
  }, [activeTab, eventId]); // Re-run the effect when activeTab changes

  return (
    <div>
      <Link to="/events" className="is-primary is-flex is-align-items-center is-size-5 has-text-grey-light hover">
        <IoArrowBackCircleOutline className="mr-2" /> Kembali
      </Link>
      <h3 className="title is-3 my-3">{event?.name || "unknown"}</h3>
      <p className=" has-text-weight-medium has-text-light mb-2">
        {" "}
        <IoPin />
        {event?.venue || "unknown"} <IoCalendarOutline className="ml-6" /> Event {event?.eventDate ? format(new Date(event.eventDate), "d MMMM yyyy", { locale: id }) : "unknown"} <IoCalendarOutline className="ml-6" /> Loading{" "}
        {event?.loadingDate ? format(new Date(event.loadingDate), "d MMMM yyyy", { locale: id }) : "unknown"}
      </p>

      <div className="tabs is-medium is-fullwidth custom-tabs">
        <ul>
          <li className={activeTab === "master" ? "is-active" : ""} onClick={() => handleTabClick("master")}>
            <a>Master</a>
          </li>
          <li className={activeTab === "suratjalan" ? "is-active" : ""} onClick={() => handleTabClick("suratjalan")}>
            <a>Surat Jalan</a>
          </li>
          <li className={activeTab === "checklist" ? "is-active" : ""} onClick={() => handleTabClick("checklist")}>
            <a>Checklist</a>
          </li>
          <li className={activeTab === "sewa" ? "is-active" : ""} onClick={() => handleTabClick("sewa")}>
            <a>Checklist Sewa</a>
          </li>
          <li className={activeTab === "pengembalian" ? "is-active" : ""} onClick={() => handleTabClick("pengembalian")}>
            <a>Pengembalian Barang</a>
          </li>
        </ul>
      </div>

      {loading ? <p>Loading...</p> : renderActiveTab()}
    </div>
  );
};

export default EventContent;
