import React, { useState } from "react";

const TabMaster = ({ handleMasterClient, handleMasterInternal }) => {
  const [users, setUsers] = useState([]);

  return (
    <div class="columns mr-2 ml-2 mt-10">
      <div class="column is-half">
        <div class="box">
          <div className="mb" style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <div>
              <h5 className="title is-5">Master Client</h5>
              <h4 className="subtitle is-6 mt-3">Gabungan data dari surat jalan</h4>
            </div>
            {/* <button className="button is-secondary mr-2" onClick={() => onChecklistClick(checklistItem, index)}>
                  Buka
                </button> */}
            <button className="button is-primary mr-2" onClick={() => handleMasterClient()}>
              Buka
            </button>
          </div>
        </div>
      </div>
      <div class="column">
        <div class="box">
          <div className="mb" style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <div>
              <h5 className="title is-5">Master Internal</h5>
              <h4 className="subtitle is-6 mt-3">Gabungan data dari checklist</h4>
            </div>
            <button className="button is-primary mr-2" onClick={() => handleMasterInternal()}>
              Buka
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TabMaster;
