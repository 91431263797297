import React, { useState } from "react";

const TabSewa = ({ itemSewa }) => {
  const [loading, setLoading] = useState(false);

  if (!itemSewa) {
    return <p>Loading surat jalan items...</p>;
  }

  return (
    <div className="div ml-5 mr-5">
      <div class="columns mb-5 mt-5">
        <div class="column is-one-third ml-3"></div>
        <div class="column has-text-centered">
          <h4 className="title is-4 mr-3">Item Sewa</h4>
        </div>
        <div class="column has-text-right mr-3"></div>
      </div>
      {loading ? (
        <div>Loading...</div> // Atau tambahkan spinner Bulma
      ) : (
        <table className="table is-striped is-fullwidth mr-6">
          <thead>
            <tr>
              <th style={{ width: "50px" }}>No</th>
              <th>Nama Item</th>
              <th>Jenis</th>
              <th className="has-text-centered">QTY</th>
              <th colSpan="2" className="has-text-centered">
                Period
              </th>
              <th className="has-text-centered">Vendor</th>
            </tr>
          </thead>
          <tbody>
            {itemSewa.map((item, index) => (
              <tr key={item.id}>
                <td>{index + 1}</td>
                <td>{item.name}</td>
                <td>Sewa</td>
                <td className="has-text-centered">
                  {item.quantity} {item.category}
                </td>
                <td className="has-text-centered" style={{ width: "10rem" }}>
                  {item.periodTime} Time
                </td>
                <td className="has-text-centered" style={{ width: "10rem" }}>
                  {item.periodeDay} Day
                </td>
                <td className="has-text-centered" style={{ width: "10rem" }}>
                  {item.vendor}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default TabSewa;
