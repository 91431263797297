import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import EventContent from "../components/event-content/EventContent";
import { getMe } from "../features/authSlice";
import Layout from "./Layout";

const EventDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isError, isLoading, user } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(getMe());
  }, [dispatch]);

  useEffect(() => {
    if (isError) {
      navigate("/");
    }
  }, [isError, navigate]);

  // if (isLoading) {
  //   return <LoadingSpinner />; // Display a loading indicator while waiting for authentication
  // }

  if (!user) {
    return null; // Prevent rendering the EventDetails until user data is available
  }

  return (
    <Layout>
      <EventContent />
    </Layout>
  );
};
export default EventDetails;
