import axios from "axios";
import { format } from "date-fns";
import id from "date-fns/locale/id"; // Import locale bahasa Indonesia
import React, { useEffect, useState } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from "react-router-dom";

registerLocale("id", id); // Daftarkan locale Indonesia

const Events = () => {
  const [events, setEvents] = useState([]);
  const [name, setName] = useState("");
  const [venue, setVenue] = useState("");
  const [orderBy, setOrderBy] = useState("");
  const [eventDate, setEventDate] = useState(new Date());
  const [loadingDate, setLoadingDate] = useState(new Date());
  const [msg, setMsg] = useState("");

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalEditOpen, setIsModalEditOpen] = useState(false);
  const [editingEvent, setEditingEvent] = useState(null);

  const openModal = () => {
    setName("");
    setVenue("");
    setOrderBy("");
    setEventDate(new Date());
    setLoadingDate(new Date());
    setIsModalOpen(true);
  };

  const openEditModal = (event = null) => {
    setName(event.name);
    setVenue(event.venue);
    setOrderBy(event.orderBy);
    setEventDate(new Date(event.eventDate));
    setLoadingDate(new Date(event.loadingDate));
    setMsg("");
    setEditingEvent(event);
    setIsModalEditOpen(true);
  };

  const closeModal = () => {
    setMsg("");
    setIsModalOpen(false);
    setIsModalEditOpen(false);
  };

  useEffect(() => {
    getEvents();
  }, []);

  const getEvents = async () => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/events`);
    setEvents(response.data);
  };

  const saveEvent = async (e) => {
    e.preventDefault();
    if (!name || !venue || !orderBy || !eventDate || !loadingDate) {
      setMsg("Lengkapi semua field untuk menambahkan event");
      return;
    }

    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/events`, {
        name: name,
        venue: venue,
        orderBy: orderBy,
        eventDate: eventDate.toISOString(),
        loadingDate: loadingDate.toISOString(),
      });
      getEvents();
      closeModal();
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg);
      }
    }
    setName("");
    setVenue("");
    setOrderBy("");
    setEventDate("");
    setLoadingDate("");
    setMsg("");
  };

  const editEvent = async (e) => {
    e.preventDefault();
    if (!name || !venue || !orderBy || !eventDate || !loadingDate) {
      setMsg("Lengkapi semua field untuk menambahkan event");
      return;
    }

    try {
      await axios.patch(`${process.env.REACT_APP_API_URL}/events/${editingEvent.id}`, {
        name: name,
        venue: venue,
        orderBy: orderBy,
        eventDate: eventDate.toISOString(),
        loadingDate: loadingDate.toISOString(),
      });
      getEvents();
      closeModal();
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg);
      }
    }
    setName("");
    setVenue("");
    setOrderBy("");
    setEventDate("");
    setLoadingDate("");
    setEditingEvent(null);
    setMsg("");
  };

  return (
    <div>
      {isModalOpen && (
        <div className="modal is-active">
          <div className="modal-background" onClick={closeModal}></div>
          <div className="modal-content">
            <div class="box is-shadowless">
              <h4 className="title is-4 mt-3 has-text-centered">Buat Event Baru</h4>

              <form onSubmit={saveEvent}>
                <p className="has-text-centered has-text-danger">{msg}</p>
                <div className="field">
                  <label className="label">Name</label>
                  <div className="control">
                    <input type="text" className="input" value={name} onChange={(e) => setName(e.target.value)} placeholder="Masukkan nama event" />
                  </div>
                </div>
                <div className="field">
                  <label className="label">Venue</label>
                  <div className="control">
                    <input type="text" className="input" value={venue} onChange={(e) => setVenue(e.target.value)} placeholder="Masukkan venue" />
                  </div>
                </div>
                <div className="field">
                  <label className="label">Order By</label>
                  <div className="control">
                    <input type="text" className="input" value={orderBy} onChange={(e) => setOrderBy(e.target.value)} placeholder="Masukkan pemesan" />
                  </div>
                </div>
                <div className="field">
                  <label className="label">Tanggal Loading</label>
                  <div className="control">
                    <DatePicker
                      selected={loadingDate}
                      onChange={(date) => {
                        setLoadingDate(date);
                      }}
                      className="input"
                      locale="id"
                      placeholderText="Masukkan tanggal loading"
                      dateFormat="d MMMM yyyy"
                    />
                  </div>
                </div>
                <div className="field">
                  <label className="label">Tanggal Event</label>
                  <div className="control">
                    <DatePicker
                      selected={eventDate}
                      onChange={(date) => {
                        setEventDate(date);
                      }}
                      className="input"
                      locale="id"
                      placeholderText="Masukkan tanggal event"
                      dateFormat="d MMMM yyyy"
                    />
                  </div>
                </div>

                <br />
                <div className="field">
                  <div className="control">
                    <button type="submit" className="button is-success is-fullwidth is-rounded">
                      Save
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <button className="modal-close is-large" aria-label="close" onClick={closeModal}></button>
        </div>
      )}
      {isModalEditOpen && (
        <div className="modal is-active">
          <div className="modal-background" onClick={closeModal}></div>
          <div className="modal-content">
            <div class="box is-shadowless">
              <h4 className="title is-4 mt-3 has-text-centered">Edit Event</h4>

              <form onSubmit={editEvent}>
                <p className="has-text-centered has-text-danger">{msg}</p>
                <div className="field">
                  <label className="label">Name</label>
                  <div className="control">
                    <input type="text" className="input" value={name} onChange={(e) => setName(e.target.value)} placeholder="Masukkan nama event" />
                  </div>
                </div>
                <div className="field">
                  <label className="label">Venue</label>
                  <div className="control">
                    <input type="text" className="input" value={venue} onChange={(e) => setVenue(e.target.value)} placeholder="Masukkan venue" />
                  </div>
                </div>
                <div className="field">
                  <label className="label">Order By</label>
                  <div className="control">
                    <input type="text" className="input" value={orderBy} onChange={(e) => setOrderBy(e.target.value)} placeholder="Masukkan pemesan" />
                  </div>
                </div>
                <div className="field">
                  <label className="label">Tanggal Loading</label>
                  <div className="control">
                    <DatePicker
                      selected={loadingDate}
                      onChange={(date) => {
                        setLoadingDate(date);
                      }}
                      className="input"
                      locale="id"
                      placeholderText="Masukkan tanggal loading"
                      dateFormat="d MMMM yyyy"
                    />
                  </div>
                </div>
                <div className="field">
                  <label className="label">Tanggal Event</label>
                  <div className="control">
                    <DatePicker
                      selected={eventDate}
                      onChange={(date) => {
                        setEventDate(date);
                      }}
                      className="input"
                      locale="id"
                      placeholderText="Masukkan tanggal event"
                      dateFormat="d MMMM yyyy"
                    />
                  </div>
                </div>

                <br />
                <div className="field">
                  <div className="control">
                    <button type="submit" className="button is-success is-fullwidth is-rounded">
                      Save
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <button className="modal-close is-large" aria-label="close" onClick={closeModal}></button>
        </div>
      )}
      <div className="mb-5" style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <div>
          <h3 className="title is-3 mt-3">Event</h3>
          <h5 className="subtitle is-5">Untuk membuat surat jalan, checklist, dan pengembalian barang, silahkan masuk ke sebuah halaman event terlebih dahulu.</h5>
        </div>
        <button className="button is-primary mr-2 " onClick={openModal}>
          Buat Event
        </button>
      </div>
      {events.length === 0 ? (
        <p>Tidak ada event.</p>
      ) : (
        events.map((event, index) => (
          <div className="card" key={event.id}>
            <header className="card-header">
              <p className="card-header-title">{event.name}</p>
              <button className="card-header-icon" aria-label="more options">
                <span className="icon">
                  <i className="fas fa-angle-down" aria-hidden="true"></i>
                </span>
              </button>
            </header>
            <div className="card-content">
              <div className="content">
                <div className="columns">
                  <div className="column is-half">Venue : {event.venue}</div>
                  <div className="column">Event : {format(new Date(event.eventDate), "d MMMM yyyy", { locale: id })}</div>
                </div>
                <div className="columns">
                  <div className="column is-half">Order by : {event.orderBy}</div>
                  <div className="column">Loading : {format(new Date(event.loadingDate), "d MMMM yyyy", { locale: id })}</div>
                </div>
              </div>
            </div>
            <footer className="card-footer">
              <Link
                to={{
                  pathname: `/events/${event.id}/details`,
                }}
                state={{ event }} // Kirim event melalui state
                className="card-footer-item"
              >
                Buka Event
              </Link>
              <Link
                to="#"
                onClick={() => openEditModal(event)} // Panggil openModal dengan event yang dipilih
                className="card-footer-item"
              >
                Edit Event
              </Link>
            </footer>
          </div>
        ))
      )}
    </div>
  );
};

export default Events;
