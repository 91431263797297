import axios from "axios";
import id from "date-fns/locale/id"; // Import locale bahasa Indonesia
import React, { useEffect, useState } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { IoAdd } from "react-icons/io5";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select"; // Import react-select untuk dropdown
import makeAnimated from "react-select/animated"; // Import animasi untuk dropdown

registerLocale("id", id); // Daftarkan locale Indonesia

const AddChecklistForm = ({ handleTabClick }) => {
  const { eventId } = useParams();
  // Checklist
  const [date, setDate] = useState(new Date());
  const [jobNo, setJobNo] = useState("");
  const [nameOranggudang, setNameOranggudang] = useState("");
  const [namePic, setNamePic] = useState("");
  const [vehicle, setVehicle] = useState("");

  // ItemChecklist
  const [selectedItem, setSelectedItem] = useState(null); // Simpan item terpilih dari dropdown
  const [quantity, setQuantity] = useState("");
  const [periodTime, setPeriodTime] = useState("");
  const [periodeDay, setPeriodeDay] = useState("");

  const [msg, setMsg] = useState("");
  const [msgItem, setMsgItem] = useState("");
  const [masterItems, setMasterItems] = useState([]); // Menyimpan data master item
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [checklistItems, setChecklistItems] = useState([]);
  // const [isSaved, setIsSaved] = useState(false);
  const [maxQuantity, setMaxQuantity] = useState(0); // State untuk menyimpan qty maksimum

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: "hsl(221, 14%, calc(9% + 0%))", // Warna latar belakang
      borderColor: "hsl(221, 14%, calc(24% + 0%))", // Warna border
      borderRadius: "var(--bulma-input-radius)", // Border radius dari Bulma
      color: "white", // Warna teks menggunakan variabel Bulma

      // Optional: Anda bisa menyesuaikan border ketika fokus
      border: state.isFocused ? "1px solid hsl(221, 14%, calc(24% + 0%))" : "1px solid hsl(221, 14%, calc(24% + 0%))",
      boxShadow: state.isFocused ? "0 0 0 2px rgba(100, 150, 250, 0.5)" : null,

      "&:hover": {
        border: "1px solid hsl(221, 14%, calc(24% + 0%))", // Warna border saat hover
      },
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "hsl(221, 14%, calc(9% + 0%))", // Background untuk menu dropdown
      color: "white",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "hsl(221, 14%, calc(9% + 0%))" : state.isFocused ? "#222928" : "hsl(221, 14%, calc(9% + 0%))", // Background opsi
      color: "#fff",
      "&:hover": {
        backgroundColor: "#222928",
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#fff", // Warna teks item yang dipilih
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#aaa", // Warna teks placeholder
    }),
    input: (provided) => ({
      ...provided,
      color: "#fff", // Warna teks saat mengetik
    }),
  };

  // Fetch data master item saat komponen di-mount
  useEffect(() => {
    const getItemsDropdown = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/dropdown`); // Ganti dengan endpoint yang sesuai
        const items = response.data.map((item) => ({
          value: item.value,
          qty: item.qty,
          name: item.name,
          category: item.category,
          label: `${item.name} - (Qty: ${item.qty} ${item.category})`,
        }));
        setMasterItems(items); // Atur items ke state masterItems
      } catch (error) {
        console.error("Error fetching dropdown items:", error);
      }
    };

    getItemsDropdown();
  }, []);

  // Fungsi untuk membuka dan menutup modal
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => {
    setMsgItem("");
    setIsModalOpen(false);
  };

  useEffect(() => {
    console.log("Checklist items updated:", checklistItems);
  }, [checklistItems]);

  // Fungsi untuk menambah item ke dalam checklist
  const addItemToChecklist = (e) => {
    e.preventDefault();

    if (!selectedItem || !quantity || !periodTime || !periodeDay) {
      setMsgItem("Lengkapi semua field untuk menambahkan item");
      return;
    }

    const newItem = {
      name: selectedItem.name, // Nama item yang dipilih
      category: selectedItem.category, // Nama item yang dipilih
      quantity,
      periodTime,
      periodeDay,
    };

    // Tambahkan item ke dalam array checklistItems
    setChecklistItems((prevItems) => [...prevItems, newItem]);

    // Reset form input item
    setSelectedItem(null); // Reset selected item
    setQuantity("");
    setPeriodTime("");
    setPeriodeDay("");
    setMsgItem("");
    closeModal();
  };

  // Fungsi untuk menyimpan checklist dan item-itemnya
  const saveChecklistAndItems = async (e) => {
    e.preventDefault();
    try {
      // Cek apakah ada item di dalam checklist
      if (!date || !jobNo || !nameOranggudang || !namePic || !vehicle) {
        setMsg("Lengkapi semua field untuk membuat checklist");
        return;
      }
      if (checklistItems.length === 0) {
        setMsg("Tambahkan setidaknya satu item ke dalam checklist");
        return;
      }
      // Simpan checklist terlebih dahulu
      const checklistResponse = await axios.post(`${process.env.REACT_APP_API_URL}/events/${eventId}/checklists`, {
        date: date.toISOString(),
        jobNo: jobNo,
        nameOranggudang: nameOranggudang,
        namePic: namePic,
        vehicle: vehicle,
      });

      const checklistId = checklistResponse.data.checklist.id; // Ambil checklistId dari respons server

      // Simpan setiap item ke dalam checklist
      for (let item of checklistItems) {
        await axios.post(`${process.env.REACT_APP_API_URL}/events/${eventId}/checklists/${checklistId}/items`, {
          name: item.name,
          category: item.category,
          quantity: item.quantity,
          periodTime: item.periodTime,
          periodeDay: item.periodeDay,
        });
      }
      handleTabClick("checklist");
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg);
      }
    }
  };

  // Menangani perubahan pada dropdown
  // const handleItemChange = (selectedOption) => {
  //   setSelectedItem(selectedOption);
  //   setMsg(selectedOption ? `Selected: ${selectedOption.label} QTY: ${selectedOption.qty} ` : ""); // Set pesan ketika item dipilih
  // };

  const handleItemChange = (selectedOption) => {
    setSelectedItem(selectedOption);
    if (selectedOption) {
      setMaxQuantity(selectedOption.qty); // Misalkan qty ada dalam objek selectedOption
    } else {
      setMaxQuantity(0);
    }
  };

  const handleQuantityChange = (e) => {
    const value = e.target.value;
    if (value <= maxQuantity && value >= 0) {
      setQuantity(value); // Update state hanya jika input tidak melebihi maxQuantity
    }
  };

  return (
    <div>
      <br />
      <h4 className="title is-4 has-text-centered mb-0">Buat Checklist</h4>

      <div className="card is-shadowless has-color-grey ml-6 mr-6">
        <div className="card-content has-background-dark pt-0">
          <div className="content">
            {/* Outer form to handle checklist saving */}
            <form onSubmit={saveChecklistAndItems}>
              <p className="mt-2 has-text-centered has-text-danger ">{msg}</p>
              <div className="field">
                <label className="label is-size-6 has-text-grey-light">Job No</label>
                <div className="control">
                  <input type="text" className="input" value={jobNo} onChange={(e) => setJobNo(e.target.value)} placeholder="Job No" />
                </div>
              </div>
              <div className="field">
                <label className="label has-text-grey-light">Tanggal Checklist</label>
                <div className="control">
                  <DatePicker selected={date} onChange={(date) => setDate(date)} className="input" locale="id" placeholderText="Pilih tanggal" dateFormat="d MMMM yyyy" />
                </div>
              </div>
              <div className="field">
                <label className="label is-size-6 has-text-grey-light">Vehicle</label>
                <div className="control">
                  <input type="text" className="input" value={vehicle} onChange={(e) => setVehicle(e.target.value)} placeholder="Vehicle" />
                </div>
              </div>
              <h4 className="subtitle is-4 has-text-white-ter mb-2 mt-5">PIC</h4>
              <div className="field">
                <label className="label is-size-6 has-text-grey-light">Nama</label>
                <div className="control">
                  <input type="text" className="input" value={namePic} onChange={(e) => setNamePic(e.target.value)} placeholder="Name" />
                </div>
              </div>
              <h4 className="subtitle is-4 has-text-white-ter mb-2 mt-5">Orang Gudang</h4>
              <div className="field">
                <label className="label is-size-6 has-text-grey-light">Nama</label>
                <div className="control">
                  <input type="text" className="input" value={nameOranggudang} onChange={(e) => setNameOranggudang(e.target.value)} placeholder="Name" />
                </div>
              </div>

              <h4 className="subtitle is-4 has-text-white-ter mb-0">Item</h4>
              <div className="columns">
                <div className="column has-text-right pt-0 pb-0">
                  <button className="button mt-0 has-text-right is-small is-info" type="button" onClick={openModal}>
                    <IoAdd />
                    Tambah Item
                  </button>
                  {isModalOpen && (
                    <div className="modal is-active">
                      <div className="modal-background" onClick={closeModal}></div>
                      <div className="modal-content">
                        <div className="box is-shadowless">
                          <h4 className="title is-4 mt-3 has-text-centered">Tambah Item</h4>
                          {/* Not using form inside modal */}
                          <div>
                            <p className="has-text-centered has-text-danger has-shadow">{msgItem}</p>
                            <div className="field">
                              <label className="label has-text-left">Name</label>
                              <div className="control select-custom ">
                                {/* Dropdown untuk memilih item */}
                                <Select
                                  styles={customStyles}
                                  className="has-text-black has-text-left"
                                  value={selectedItem}
                                  onChange={handleItemChange} // Gunakan fungsi yang menangani perubahan
                                  options={masterItems} // Opsi diambil dari master items
                                  placeholder="Select or search item"
                                  isClearable
                                  isSearchable
                                  components={makeAnimated()}
                                />
                              </div>
                            </div>
                            <div className="field">
                              <label className="label has-text-left">QTY</label>
                              <div className="control">
                                <input type="number" className="input" value={quantity} onChange={handleQuantityChange} placeholder="Quantity" />
                                <p className="help has-text-left is-danger">Max quantity: {maxQuantity}</p> {/* Menampilkan batas kuantitas */}
                              </div>
                            </div>
                            <div className="field">
                              <label className="label has-text-left">Period Time</label>
                              <div className="control">
                                <input type="text" className="input" value={periodTime} onChange={(e) => setPeriodTime(e.target.value)} placeholder="Period Time" />
                              </div>
                            </div>
                            <div className="field">
                              <label className="label has-text-left">Period Day</label>
                              <div className="control">
                                <input type="text" className="input" value={periodeDay} onChange={(e) => setPeriodeDay(e.target.value)} placeholder="Period Day" />
                              </div>
                            </div>
                            <div className="field">
                              <div className="control">
                                <button className="mt-5 button is-success is-fullwidth is-rounded" onClick={addItemToChecklist}>
                                  Simpan
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <button className="modal-close is-large" aria-label="close" onClick={closeModal}></button>
                    </div>
                  )}
                </div>
              </div>

              <table className="table is-striped is-fullwidth has-text-grey-lighter">
                <thead>
                  <tr className="">
                    <th>No</th>
                    <th>Product Name</th>
                    <th>QTY</th>
                    <th>Time</th>
                    <th>Day</th>
                  </tr>
                </thead>
                <tbody>
                  {checklistItems.map((item, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{item.name}</td>
                      <td>
                        {item.quantity} {item.category}
                      </td>
                      <td>{item.periodTime}</td>
                      <td>{item.periodeDay}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <br />
              <br />
              <div className="columns mt-5">
                <div className="column">
                  <div class="columns">
                    <div class="column is-half"></div>
                    <div class="column">
                      <button type="button" className="button is-rounded is-fullwidth">
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
                <div className="column">
                  <div class="columns">
                    <div class="column is-half">
                      <button type="submit" className="button is-rounded is-fullwidth disabled={loading}">
                        Simpan
                      </button>
                    </div>
                    <div class="column"></div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddChecklistForm;
