import React, { useState } from "react";
// import {Link} from "react-router-dom";
// import {IoArrowBackCircleOutline} from "react-icons/io5";

const TabMasterInternal = ({ checklistItems, handleMasterClient, handleBackMaster }) => {
  const [loading, setLoading] = useState(false);

  return (
    <div className="div ml-5 mr-5">
      <div class="columns mb-5 mt-5">
        <div class="column is-one-third ml-3">
          <button className="button is-secondary mr-2 is-text" onClick={() => handleBackMaster()}>
            Kembali
          </button>
        </div>
        {/* <Link to="/events" className="is-primary is-flex is-align-items-center is-size-5 has-text-grey-light hover">
        <IoArrowBackCircleOutline className="mr-2" /> Kembali
      </Link> */}
        <div class="column has-text-centered">
          <h4 className="title is-4 mr-3">Master Internal</h4>
        </div>
        <div class="column has-text-right mr-3">
          <button className="button is-secondary mr-2 is-text" onClick={() => handleMasterClient()}>
            Buka Master Client
          </button>
        </div>
      </div>
      {loading ? (
        <div>Loading...</div> // Atau tambahkan spinner Bulma
      ) : (
        <table className="table is-striped is-fullwidth mr-6">
          <thead>
            <tr>
              <th style={{ width: "50px" }}>No</th>
              <th>Nama Item</th>
              <th className="has-text-centered">QTY</th>
              <th colSpan="2" className="has-text-centered">
                Period
              </th>
            </tr>
          </thead>
          <tbody>
            {checklistItems.map((checklistItem, index) => (
              <tr key={checklistItem.id}>
                <td>{index + 1}</td>
                <td>{checklistItem.name}</td>
                <td className="has-text-centered">
                  {checklistItem.quantity === 0 ? checklistItem.historyQty : checklistItem.quantity} {checklistItem.category}
                </td>
                <td className="has-text-centered" style={{ width: "10rem" }}>
                  {checklistItem.periodTime} Time
                </td>
                <td className="has-text-centered" style={{ width: "10rem" }}>
                  {checklistItem.periodeDay} Day
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default TabMasterInternal;
