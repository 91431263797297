import axios from "axios";
import React, { useEffect, useState } from "react";
import "../../custom.css";

const TabItem = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false); // State untuk loading
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [msg, setMsg] = useState("");
  const [name, setName] = useState("");
  const [category, setCategory] = useState("");
  const [jenisBarang, setJenisBarang] = useState("");
  const [qtyBaik, setQtyBaik] = useState("");
  const [price1, setPrice1] = useState("");
  const [price3, setPrice3] = useState("");

  const [editingId, setEditingId] = useState("");

  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null); // Simpan item yang ingin dihapus

  useEffect(() => {
    setLoading(true); // Set loading to true saat memulai fetch
    getItems();
  }, []); // Re-run the effect when activeTab changes

  const getItems = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/items`);
      setProducts(response.data);
    } catch (error) {
      console.error("Failed to fetch event items", error);
    }
    setLoading(false); // Set loading ke false setelah fetch
  };

  const deleteItem = async (itemId) => {
    await axios.delete(`${process.env.REACT_APP_API_URL}/items/${itemId}`);
    getItems();
  };
  const infoItem = async (itemId) => {
    const getItemById = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/items/${itemId}`);
        setName(response.data.name);
        setCategory(response.data.category);
        setJenisBarang(response.data.jenisBarang);
        setQtyBaik(response.data.qtyBaik);
        setPrice1(formatRupiah(response.data.price1));
        setPrice3(formatRupiah(response.data.price3));
        setMsg("");
      } catch (error) {
        if (error.response) {
          setMsg(error.response.data.msg);
        }
      }
    };
    getItemById();
  };

  const updateItem = async (e) => {
    e.preventDefault();
    try {
      await axios.patch(`${process.env.REACT_APP_API_URL}/items/${editingId}`, {
        name: name,
        category: category,
        jenisBarang: jenisBarang,
        qtyBaik: qtyBaik,
        price1: parseInt(unformatRupiah(price1)),
        price3: parseInt(unformatRupiah(price3)),
      });
      getItems();
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg);
      }
    }
    setName("");
    setCategory("");
    setJenisBarang("");
    setQtyBaik("");
    setPrice1("");
    setPrice3("");
    closeModal();
  };

  const openModal = (itemId) => {
    setEditingId(itemId);
    infoItem(itemId);
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setEditingId("");
    setIsModalOpen(false);
  };

  const openConfirmModal = (itemId) => {
    setItemToDelete(itemId); // Simpan id item yang ingin dihapus
    setIsConfirmModalOpen(true); // Tampilkan modal konfirmasi
  };

  const closeConfirmModal = () => {
    setItemToDelete(null); // Hapus item yang ingin dihapus dari state
    setIsConfirmModalOpen(false); // Tutup modal konfirmasi
  };

  // Fungsi untuk memformat angka menjadi mata uang Rupiah
  const formatRupiah = (amount) => {
    return new Intl.NumberFormat("id-ID", {
      style: "currency",
      currency: "IDR",
      minimumFractionDigits: 0, // Do not show decimal places
      maximumFractionDigits: 0, // Do not show decimal places
    }).format(amount);
  };

  // Fungsi untuk menghapus format Rupiah sebelum dikirim ke backend
  const unformatRupiah = (number) => {
    return number.replace(/[^,\d]/g, ""); // Hanya menyisakan angka
  };

  // Fungsi untuk menangani perubahan input dan langsung memformat ke Rupiah
  const handlePriceChange1 = (e) => {
    const value = e.target.value;
    setPrice1(formatRupiah(unformatRupiah(value))); // Set dengan format Rupiah
  };

  const handlePriceChange3 = (e) => {
    const value = e.target.value;
    setPrice3(formatRupiah(unformatRupiah(value))); // Set dengan format Rupiah
  };

  const formatDate = (dateString) => {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      // second: "numeric",
      timeZone: "Asia/Jakarta", // Specify the Indonesian timezone
    };

    return new Intl.DateTimeFormat("id-ID", options).format(new Date(dateString));
  };

  return (
    <div>
      <br />
      {loading ? (
        <p>Loading...</p>
      ) : (
        <>
          {isModalOpen && (
            <div className="modal is-active">
              <div className="modal-background" onClick={closeModal}></div>
              <div className="modal-content">
                <div className="box is-shadowless">
                  <h4 className="title is-4 mt-3 has-text-centered">Tambah Item</h4>
                  <div>
                    <p className="has-text-centered">{msg}</p>
                    <div className="field">
                      <label className="label has-text-left">Nama Item</label>
                      <div className="control">
                        <input type="text" className="input" value={name} onChange={(e) => setName(e.target.value)} placeholder="" />
                      </div>
                    </div>
                    <div className="field">
                      <label className="label has-text-left">Category</label>
                      <div className="control">
                        <input type="text" className="input" value={category} onChange={(e) => setCategory(e.target.value)} placeholder="" />
                      </div>
                    </div>
                    <div className="field">
                      <label className="label has-text-grey-light">Jenis</label>
                      <div className="control">
                        <div className="select is-fullwidth">
                          <select value={jenisBarang} onChange={(e) => setJenisBarang(e.target.value)}>
                            <option value="item event">Alat Event</option>
                            <option value="item kerja">Alat Kerja</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="field">
                      <label className="label has-text-left">Quantity</label>
                      <div className="control">
                        <input type="number" className="input" value={qtyBaik} onChange={(e) => setQtyBaik(e.target.value)} placeholder="" min="0" />
                      </div>
                    </div>
                    <div className="field">
                      <label className="label has-text-left">Harga Terendah</label>
                      <div className="control">
                        <input type="text" className="input" value={price1} onChange={handlePriceChange1} placeholder="" />
                      </div>
                    </div>
                    <div className="field">
                      <label className="label has-text-left">Harga Tertinggi</label>
                      <div className="control">
                        <input type="text" className="input" value={price3} onChange={handlePriceChange3} placeholder="" />
                      </div>
                    </div>
                    <div className="field">
                      <div className="control">
                        <button className="button is-success is-fullwidth is-rounded" onClick={updateItem}>
                          Update Item
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <button className="modal-close is-large" aria-label="close" onClick={closeModal}></button>
            </div>
          )}

          {isConfirmModalOpen && (
            <div className="modal is-active">
              <div className="modal-background" onClick={closeConfirmModal}></div>
              <div className="modal-content">
                <div className="box">
                  <p className="has-text-centered">Apakah Anda yakin ingin menghapus item ini?</p>
                  <div className="buttons is-centered mt-4">
                    <button
                      className="button"
                      onClick={() => {
                        deleteItem(itemToDelete); // Panggil fungsi delete item
                        closeConfirmModal(); // Tutup modal setelah menghapus
                      }}
                    >
                      Iya
                    </button>
                    <button className="button is-danger" onClick={closeConfirmModal}>
                      Tidak
                    </button>
                  </div>
                </div>
              </div>
              <button className="modal-close is-large" aria-label="close" onClick={closeConfirmModal}></button>
            </div>
          )}

          <table className="table is-striped is-fullwidth mr-5">
            <thead>
              <tr>
                <th>No</th>
                <th>Nama Item</th>
                <th>Jenis</th>
                <th>Quantity</th>
                <th>Harga</th>
                <th>Terakhir Edit</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {products.map((product, index) => (
                <tr key={product.uuid}>
                  <td>{index + 1}</td>
                  <td>{product.name}</td>
                  <td>{product.jenisBarang === "item event" ? "Event" : product.jenisBarang === "item kerja" ? "Alat kerja" : product.jenisBarang}</td>
                  <td>
                    {product.qtyBaik} {product.category}
                  </td>
                  <td>
                    {formatRupiah(product.price1)} - {formatRupiah(product.price3)}
                  </td>
                  <td>
                    {product.UpdatedByUser.name} - {formatDate(product.updatedAt)} WIB
                  </td>
                  <td>
                    <button type="button" className="button is-small is-info mr-2" onClick={() => openModal(product.uuid)}>
                      Edit
                    </button>
                    <button onClick={() => openConfirmModal(product.uuid)} className="button is-small is-danger">
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      )}
    </div>
  );
};

export default TabItem;
