import axios from "axios";
import React, { useEffect, useState } from "react";

const TabPengembalian = ({ checklistItems, isPengembalianEditable, eventId }) => {
  const [loading, setLoading] = useState(false);
  const [eventRefetch, setEventRefetch] = useState(false);
  const [firstEvent, setFirstEvent] = useState(true);
  const [isEditableEvent, setIsEditableEvent] = useState(true);
  const [msg, setMsg] = useState("");
  const [formValues, setFormValues] = useState(() => {
    // Coba ambil data dari localStorage
    const savedFormValues = localStorage.getItem("formValues");
    return savedFormValues
      ? JSON.parse(savedFormValues)
      : checklistItems.reduce((acc, item) => {
          acc[item.id] = {
            qtyBaik: "",
            qtyRusak: "",
            qtyHilang: "",
          };
          return acc;
        }, {});
  });

  // Simpan data sementara ke localStorage setiap kali formValues berubah
  useEffect(() => {
    localStorage.setItem("formValues", JSON.stringify(formValues));
  }, [formValues]);

  const handleChange = (e, id, field) => {
    setFormValues((prev) => ({
      ...prev,
      [id]: {
        ...prev[id],
        [field]: e.target.value,
      },
    }));
  };

  const handleSubmit = async () => {
    // Validasi jika semua field sudah terisi
    const allFieldsFilled = checklistItems.every((item) => {
      const itemValues = formValues[item.id];
      return (
        itemValues &&
        itemValues.qtyBaik !== "" &&
        itemValues.qtyRusak !== "" &&
        itemValues.qtyHilang !== "" &&
        itemValues.qtyBaik !== null &&
        itemValues.qtyRusak !== null &&
        itemValues.qtyHilang !== null &&
        itemValues.qtyBaik !== undefined &&
        itemValues.qtyRusak !== undefined &&
        itemValues.qtyHilang !== undefined
      );
    });

    if (!allFieldsFilled) {
      alert("Semua kolom harus memiliki nilai, termasuk qtyBaik, qtyRusak, dan qtyHilang.");
      return;
    }

    setLoading(true);

    // Buat array `pengembalianItems` yang sesuai dengan format yang dibutuhkan back-end
    const pengembalianItems = checklistItems.map((item) => ({
      id: item.id, // id dari checklist item
      historyQtyBaik: parseInt(formValues[item.id].qtyBaik, 10), // jumlah barang baik
      historyQtyRusak: parseInt(formValues[item.id].qtyRusak, 10), // jumlah barang rusak
      historyQtyHilang: parseInt(formValues[item.id].qtyHilang, 10), // jumlah barang hilang
    }));

    try {
      console.log("Sending request to:", `${process.env.REACT_APP_API_URL}/events/${eventId}/pengembalianbulk`);
      // Lakukan request POST ke backend menggunakan axios
      const response = await axios.patch(`${process.env.REACT_APP_API_URL}/events/${eventId}/pengembalianbulk`, {
        pengembalianItems, // Kirim data pengembalianItems
      });

      if (response.status !== 200) {
        throw new Error("Failed to save pengembalian");
      }

      alert("Pengembalian berhasil disimpan!");
      setEventRefetch(true); // Set state to trigger refetch
      // Hapus data dari localStorage setelah berhasil submit
      localStorage.removeItem("formValues");
    } catch (error) {
      if (error.response) {
        alert(error.response.data.msg);
        setMsg(error.response.data.msg);
      }
      console.error("Error during pengembalian:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchEventData = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/events/${eventId}`);
        // Update your state with the new event data as needed
        setIsEditableEvent(response.data.isPengembalianEditable);
      } catch (error) {
        console.error("Error fetching event data:", error);
      } finally {
        setEventRefetch(false); // Reset refetch state
        setLoading(false);
      }
    };

    // Jalankan pengambilan data saat render pertama atau jika `eventRefetch` bernilai true
    if (eventRefetch || eventRefetch === false) {
      fetchEventData();
    }
  }, [eventRefetch, eventId]); // Only run when eventRefetch changes

  if (!checklistItems) {
    return <p>Loading surat jalan items...</p>;
  }

  return (
    <div className="div ml-5 mr-5">
      <div className="columns mb-5 mt-5">
        <div className="column is-one-third ml-3"></div>
        <div className="column has-text-centered">
          <h4 className="title is-4 mr-3">Pengembalian Barang</h4>
        </div>
        <div className="column has-text-right mr-3"></div>
      </div>

      {loading ? (
        <div>Loading...</div> // Atau tambahkan spinner Bulma
      ) : (
        <>
          <table className="table is-striped is-fullwidth mr-6">
            <thead>
              <tr>
                <th rowSpan="2" className="has-text-centered" style={{ width: "50px", verticalAlign: "middle" }}>
                  No
                </th>
                <th rowSpan="2" className="has-text-centered" style={{ verticalAlign: "middle" }}>
                  Nama Item
                </th>
                <th rowSpan="2" colSpan="2" className="has-text-centered" style={{ verticalAlign: "middle" }}>
                  Period
                </th>
                <th rowSpan="2" className="has-text-centered" style={{ verticalAlign: "middle" }}>
                  QTY
                </th>
                <th colSpan="3" className="has-text-centered">
                  Pengembalian
                </th>
              </tr>
              <tr>
                <th className="has-text-centered" style={{ width: "6rem" }}>
                  Baik
                </th>
                <th className="has-text-centered" style={{ width: "6rem" }}>
                  Rusak
                </th>
                <th className="has-text-centered" style={{ width: "6rem" }}>
                  Hilang
                </th>
              </tr>
            </thead>
            <tbody>
              {checklistItems.map((checklistItem, index) => (
                <tr key={checklistItem.id}>
                  <td>{index + 1}</td>
                  <td>{checklistItem.name}</td>
                  <td className="has-text-centered" style={{ width: "10rem" }}>
                    {checklistItem.periodTime} Time
                  </td>
                  <td className="has-text-centered" style={{ width: "10rem" }}>
                    {checklistItem.periodeDay} Day
                  </td>
                  <td className="has-text-centered">
                    {checklistItem.quantity === 0 ? checklistItem.historyQty : checklistItem.quantity} {checklistItem.category}
                  </td>
                  <td className="has-text-centered">
                    <input
                      type="number"
                      className="input"
                      value={formValues[checklistItem.id]?.qtyBaik || ""}
                      onChange={(e) => handleChange(e, checklistItem.id, "qtyBaik")}
                      placeholder={checklistItem.historyQtyBaik}
                      disabled={!isEditableEvent}
                    />
                  </td>
                  <td className="has-text-centered">
                    <input
                      type="number"
                      className="input"
                      value={formValues[checklistItem.id]?.qtyRusak || ""}
                      onChange={(e) => handleChange(e, checklistItem.id, "qtyRusak")}
                      placeholder={checklistItem.historyQtyRusak}
                      disabled={!isEditableEvent}
                    />
                  </td>
                  <td className="has-text-centered">
                    <input
                      type="number"
                      className="input"
                      value={formValues[checklistItem.id]?.qtyHilang || ""}
                      onChange={(e) => handleChange(e, checklistItem.id, "qtyHilang")}
                      placeholder={checklistItem.historyQtyHilang}
                      disabled={!isEditableEvent}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <div className="has-text-right mt-4">
            <button className="button is-primary is-fullwidth" onClick={handleSubmit} disabled={!isEditableEvent}>
              {isEditableEvent ? "Simpan Pengembalian" : "Item Sudah Tersimpan"}
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default TabPengembalian;
