import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "./components/Login";
import AddProduct from "./pages/AddProduct";
import AddUser from "./pages/AddUser";
import Admin from "./pages/Admin";
import Dashboard from "./pages/Dashboard";
import EditProduct from "./pages/EditProduct";
import EditUser from "./pages/EditUser";
import Event from "./pages/Event";
import EventDetails from "./pages/EventDetails";
import EventAddChecklist from "./pages/EventAddChecklist";
import Products from "./pages/Products";
import Users from "./pages/Users";
import RusakHilang from "./pages/RusakHilang";

function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/users" element={<Users />} />
          <Route path="/users/add" element={<AddUser />} />
          <Route path="/users/edit/:id" element={<EditUser />} />
          <Route path="/products" element={<Products />} />
          <Route path="/rusakhilang" element={<RusakHilang />} />
          <Route path="/products/add" element={<AddProduct />} />
          <Route path="/products/edit/:id" element={<EditProduct />} />
          <Route path="/events" element={<Event />} />
          <Route path="/events/:eventId/details" element={<EventDetails />} />
          <Route path="/events/test" element={<EventAddChecklist />} />
          <Route path="/admin" element={<Admin />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
