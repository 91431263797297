import axios from "axios";
import React, { useEffect, useState } from "react";
import "../custom.css";

const ProductList = () => {
  const [products, setProducts] = useState([]);
  const [activeTab, setActiveTab] = useState("rusak"); // default tab 'rusak'
  const [loading, setLoading] = useState(false); // State untuk loading
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editingId, setEditingId] = useState("");
  const [name, setName] = useState("");
  const [qtyRusak, setQtyRusak] = useState("");
  const [qtyBaik, setQtyBaik] = useState("");
  const [qtyRusakUpdated, setQtyRusakUpdated] = useState("");
  const [msg, setMsg] = useState("");
  const [error, setError] = useState(""); // State untuk menampung pesan error

  useEffect(() => {
    setLoading(true); // Set loading to true saat memulai fetch
    if (activeTab === "rusak") {
      getItemRusak();
    } else {
      getItemhilang();
    }
  }, [activeTab]); // Re-run the effect when activeTab changes

  const getItemRusak = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/items?kondisi=rusak`);
      setProducts(response.data);
    } catch (error) {
      console.error("Failed to fetch event items", error);
    }
    setLoading(false); // Set loading ke false setelah fetch
  };

  const getItemhilang = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/items?kondisi=hilang`);
      setProducts(response.data);
    } catch (error) {
      console.error("Failed to fetch work items", error);
    }
    setLoading(false); // Set loading ke false setelah fetch
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab); // Change the active tab when clicked
  };

  const infoItem = async (itemId) => {
    const getItemById = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/items/${itemId}`);
        setName(response.data.name);
        setQtyRusak(response.data.qtyRusak);
        setQtyBaik(response.data.qtyBaik);
        setMsg("");
      } catch (error) {
        if (error.response) {
          setMsg(error.response.data.msg);
        }
      }
    };
    getItemById();
  };

  const updateItem = async (e) => {
    e.preventDefault();
    if (parseInt(qtyRusakUpdated, 10) > qtyRusak) {
      setError("Jumlah yang diperbaiki tidak boleh melebihi quantity rusak!");
      return; // Batalkan jika input tidak valid
    }
    try {
      const qtyBaikNumber = parseInt(qtyBaik, 10); // atau parseFloat jika desimal
      const qtyRusakNumber = parseInt(qtyRusak, 10); // atau parseFloat jika desimal
      const qtyRusakUpdatedNumber = parseInt(qtyRusakUpdated, 10); // atau parseFloat jika desimal
      await axios.patch(`${process.env.REACT_APP_API_URL}/items/${editingId}`, {
        qtyRusak: qtyRusakNumber - qtyRusakUpdatedNumber,
        qtyBaik: qtyBaikNumber + qtyRusakUpdatedNumber,
      });
      getItemRusak();
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg);
      }
    }
    setName("");
    setQtyRusakUpdated("");
    setQtyRusak("");
    closeModal();
  };

  const handleQtyRusakUpdatedChange = (e) => {
    const updatedValue = e.target.value;
    setQtyRusakUpdated(updatedValue);

    // Validasi jika qtyRusakUpdated melebihi qtyRusak
    if (parseInt(updatedValue, 10) > qtyRusak) {
      setError("Jumlah yang diperbaiki tidak boleh melebihi quantity rusak!");
    } else {
      setError(""); // Kosongkan error jika valid
    }
  };

  const openModal = (itemId) => {
    setEditingId(itemId);
    infoItem(itemId);
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setEditingId("");
    setQtyRusakUpdated("");
    setIsModalOpen(false);
  };

  return (
    <div>
      {isModalOpen && (
        <div className="modal is-active">
          <div className="modal-background" onClick={closeModal}></div>
          <div className="modal-content">
            <div className="box is-shadowless">
              <h4 className="title is-4 mt-3 has-text-centered">Update Barang Rusak</h4>
              <div>
                <p className="has-text-centered">{msg}</p>
                <div className="field">
                  <label className="label has-text-left">Nama Item</label>
                  <div className="control">
                    <input type="text" className="input" value={name} onChange={(e) => setName(e.target.value)} placeholder="" disabled={true} />
                  </div>
                </div>
                <div className="field">
                  <label className="label has-text-left">Quantity Rusak</label>
                  <div className="control">
                    <input type="text" className="input" value={qtyRusak} onChange={(e) => setQtyRusak(e.target.value)} placeholder="" disabled={true} />
                  </div>
                </div>

                <div className="field">
                  <label className="label has-text-left">Item yang sudah diperbaiki</label>
                  <div className="control">
                    <input type="number" className="input" value={qtyRusakUpdated} onChange={handleQtyRusakUpdatedChange} placeholder="" min="0" />
                  </div>
                  {error && <p className="help is-danger">{error}</p>} {/* Tampilkan error */}
                </div>
                <br />
                <div className="field">
                  <div className="control">
                    <button className="button is-success is-fullwidth is-rounded" onClick={updateItem}>
                      Update Item
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <button className="modal-close is-large" aria-label="close" onClick={closeModal}></button>
        </div>
      )}
      <h3 className="title is-3 mt-3">Item Rusak dan Hilang</h3>

      {/* Tabs */}
      <div className="tabs is-medium is-fullwidth custom-tabs">
        {" "}
        {/* Mengganti class menjadi className */}
        <ul>
          <li className={activeTab === "rusak" ? "is-active" : ""} onClick={() => handleTabClick("rusak")}>
            <a>Rusak</a>
          </li>
          <li className={activeTab === "hilang" ? "is-active" : ""} onClick={() => handleTabClick("hilang")}>
            <a>Hilang</a>
          </li>
        </ul>
      </div>

      {/* Loading state handling */}
      {loading ? (
        <p>Loading...</p>
      ) : (
        <table className="table is-striped is-fullwidth mr-5">
          <thead>
            <tr>
              <th>No</th>
              <th>Nama Item</th>
              <th>Jenis</th>
              <th>Quantity</th>
              {activeTab === "rusak" && <th>Action</th>}
            </tr>
          </thead>
          <tbody>
            {products.map((product, index) => (
              <tr key={product.uuid}>
                <td>{index + 1}</td>
                <td>{product.name}</td>
                <td>{product.jenisBarang === "item event" ? "Event" : product.jenisBarang === "item kerja" ? "Alat kerja" : product.jenisBarang}</td>
                <td>{activeTab === "rusak" ? `${product.qtyRusak} ${product.category}` : `${product.qtyHilang} ${product.category}`}</td>
                {activeTab === "rusak" && (
                  <td>
                    <button className="button is-small is-info" onClick={() => openModal(product.uuid)}>
                      Edit
                    </button>
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default ProductList;
