import { format } from "date-fns";
import id from "date-fns/locale/id"; // Import locale bahasa Indonesia
import React from "react";
import { IoAdd } from "react-icons/io5";

const TabChecklist = ({ checklist, onChecklistClick, onChecklistAdd }) => {
  return (
    <div className="columns mr-2 ml-2 mt-10">
      <div className="column is-four-fifths">
        {checklist.length === 0 ? (
          <p>Belum ada checklist untuk event ini.</p>
        ) : (
          checklist.map((checklistItem, index) => (
            <div className="box" key={checklistItem.id}>
              <div className="mb" style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <div>
                  <h5 className="title is-5">Checklist {index + 1}</h5>
                  <h4 className="subtitle is-6 mt-3">
                    Job No: {checklistItem.jobNo} | Dibuat pada tanggal {format(new Date(checklistItem.date), "d MMMM yyyy", { locale: id })}
                  </h4>
                </div>
                <button className="button is-secondary mr-2" onClick={() => onChecklistClick(checklistItem, index)}>
                  Buka
                </button>
              </div>
            </div>
          ))
        )}
      </div>
      <div className="column has-text-centered mt-2">
        <button className="button is-primary " onClick={() => onChecklistAdd()}>
          <IoAdd className="mr-2" /> Tambah Checklist
        </button>
        {/* <NavLink to={"/events/test"}>Dashboard</NavLink> */}
      </div>
    </div>
  );
};

export default TabChecklist;
