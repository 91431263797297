import axios from "axios";
import { format } from "date-fns";
import id from "date-fns/locale/id"; // Import locale bahasa Indonesia
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import React, { useEffect, useState } from "react";
import { IoPrintOutline } from "react-icons/io5";

const TabSuratJalanDetail = ({ selectedSuratJalan, checklist, title }) => {
  const [loading, setLoading] = useState(false); // State untuk loading
  // const [checklistItems, setChecklistItem] = useState([]);
  const [suratJalanItems, setSuratJalanItem] = useState([]);
  const [event, setEvent] = useState([]);

  useEffect(() => {
    if (selectedSuratJalan) {
      getChecklistItem();
      getEvent();
    }
  }, [selectedSuratJalan]); // Mengambil data ketika selectedSuratJalan berubah

  const getEvent = async () => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/events/${selectedSuratJalan.eventId}`);
    setEvent(response.data);
  };

  const getChecklistItem = async () => {
    setLoading(true); // Mulai loading sebelum fetch
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/events/${selectedSuratJalan.eventId}/suratjalan/${selectedSuratJalan.id}/items`);
      setSuratJalanItem(response.data);
      console.log(suratJalanItems);
    } catch (error) {
      console.error("Failed to fetch checklist items", error);
    }
    setLoading(false); // Set loading ke false setelah fetch
  };

  const fetchTemplate = async () => {
    const response = await fetch(`${process.env.PUBLIC_URL}/template-sj-v1.xlsx`);
    if (!response.ok) {
      throw new Error("Failed to fetch the Excel template");
    }
    const arrayBuffer = await response.arrayBuffer();
    const workbook = new ExcelJS.Workbook();
    await workbook.xlsx.load(arrayBuffer);

    return workbook;
  };

  const exportToExcel = async () => {
    try {
      // Ambil workbook dari file template
      const workbook = await fetchTemplate();
      const worksheet = workbook.getWorksheet(1); // Ambil worksheet yang sesuai

      // Tulis data produk ke worksheet, mulai dari baris ke-18
      const border = {
        top: { style: "dotted" },
        left: { style: "thin" },
        bottom: { style: "dotted" },
        right: { style: "thin" },
      };
      const borderTengah = {
        top: { style: "dotted" },
        bottom: { style: "dotted" },
      };
      const borderKiri = {
        top: { style: "dotted" },
        bottom: { style: "dotted" },
        left: { style: "thin" },
      };
      const borderKanan = {
        top: { style: "dotted" },
        bottom: { style: "dotted" },
        right: { style: "thin" },
      };

      worksheet.getCell("J3").value = ": " + format(new Date(selectedSuratJalan.date), "d MMMM yyyy", { locale: id });
      worksheet.getCell("J4").value = ": " + selectedSuratJalan.jobNo;
      worksheet.getCell("J5").value = ": " + selectedSuratJalan.namePengirim;
      worksheet.getCell("J6").value = ": " + selectedSuratJalan.namePenerima;
      let rowIndex = 12;
      suratJalanItems.forEach((item, index) => {
        worksheet.getCell(`B${rowIndex}`).value = index + 1;
        worksheet.getCell(`C${rowIndex}`).value = item.name;
        worksheet.getCell(`E${rowIndex}`).value = item.quantity;
        worksheet.getCell(`F${rowIndex}`).value = item.category;
        // worksheet.getCell(`H${rowIndex}`).value = "Time";
        // worksheet.getCell(`I${rowIndex}`).value = item.periodeDay;
        // worksheet.getCell(`J${rowIndex}`).value = "Day";

        worksheet.getCell(`B${rowIndex}`).border = border;
        worksheet.getCell(`C${rowIndex}`).border = borderKiri;
        worksheet.getCell(`D${rowIndex}`).border = borderKanan;
        worksheet.getCell(`E${rowIndex}`).border = borderKiri;
        worksheet.getCell(`F${rowIndex}`).border = borderKiri;
        worksheet.getCell(`G${rowIndex}`).border = borderKiri;
        worksheet.getCell(`H${rowIndex}`).border = borderTengah;
        worksheet.getCell(`I${rowIndex}`).border = borderTengah;
        worksheet.getCell(`J${rowIndex}`).border = borderTengah;
        // worksheet.getCell(`K${rowIndex}`).border = border;
        // worksheet.getCell(`L${rowIndex}`).border = border;
        // worksheet.getCell(`M${rowIndex}`).border = border;
        worksheet.getCell(`K${rowIndex}`).border = borderKanan;

        // Menambahkan border untuk kolom B hingga J
        // for (let col = 2; col <= 10; col++) {
        //   const cell = worksheet.getCell(currentRow, col); // Ambil sel berdasarkan indeks baris dan kolom
        //   cell.border = border; // Terapkan border
        // }

        rowIndex++;
      });

      const row = worksheet.getRow(rowIndex);
      row.height = 23.5;

      worksheet.mergeCells(`D${rowIndex + 1}:E${rowIndex + 1}`);
      worksheet.mergeCells(`F${rowIndex + 1}:I${rowIndex + 1}`);
      worksheet.getCell(`D${rowIndex + 1}`).value = "Yang Menyerahkan";
      worksheet.getCell(`F${rowIndex + 1}`).value = "Yang Menerima";
      worksheet.getCell(`D${rowIndex + 1}`).alignment = { vertical: "middle", horizontal: "center" };

      worksheet.getCell(`D${rowIndex + 1}`).border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
      worksheet.getCell(`F${rowIndex + 1}`).border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };

      worksheet.getCell(`D${rowIndex + 2}`).border = {
        left: { style: "thin" },
      };
      worksheet.getCell(`F${rowIndex + 2}`).border = {
        left: { style: "thin" },
      };
      worksheet.getCell(`J${rowIndex + 2}`).border = {
        left: { style: "thin" },
      };

      worksheet.getCell(`D${rowIndex + 3}`).border = {
        left: { style: "thin" },
      };
      worksheet.getCell(`F${rowIndex + 3}`).border = {
        left: { style: "thin" },
      };
      worksheet.getCell(`J${rowIndex + 3}`).border = {
        left: { style: "thin" },
      };

      worksheet.getCell(`D${rowIndex + 4}`).border = {
        left: { style: "thin" },
      };
      worksheet.getCell(`F${rowIndex + 4}`).border = {
        left: { style: "thin" },
      };
      worksheet.getCell(`J${rowIndex + 4}`).border = {
        left: { style: "thin" },
      };

      worksheet.getCell(`D${rowIndex + 5}`).border = {
        top: { style: "thin" },
      };
      worksheet.getCell(`E${rowIndex + 5}`).border = {
        top: { style: "thin" },
      };
      worksheet.getCell(`F${rowIndex + 5}`).border = {
        top: { style: "thin" },
      };
      worksheet.getCell(`G${rowIndex + 5}`).border = {
        top: { style: "thin" },
      };
      worksheet.getCell(`H${rowIndex + 5}`).border = {
        top: { style: "thin" },
      };
      worksheet.getCell(`I${rowIndex + 5}`).border = {
        top: { style: "thin" },
      };

      const startCol = "B"; // Kolom mulai
      const endCol = "K"; // Kolom akhir
      const rows = [rowIndex, rowIndex + 8]; // Baris yang akan diberi border

      // Loop untuk setiap baris yang ingin diberi border
      rows.forEach((rowNumber) => {
        // Loop untuk setiap kolom dari B hingga N
        for (let col = startCol.charCodeAt(0); col <= endCol.charCodeAt(0); col++) {
          const cell = worksheet.getCell(String.fromCharCode(col) + rowNumber);

          // Menentukan border untuk setiap sel pada baris tersebut
          cell.border = {
            top: { style: "thin" },
          };
        }
      });

      // Menentukan kolom dan rentang baris
      const columns = ["B", "L"]; // Kolom B
      const startRow = rowIndex; // Baris mulai
      const endRow = rowIndex + 7; // Baris akhir

      // Menambah border untuk setiap baris pada kolom B dari baris 20 hingga 38
      for (let row = startRow; row <= endRow; row++) {
        columns.forEach((column) => {
          const cell = worksheet.getCell(`${column}${row}`);
          // Menentukan border untuk setiap sel pada kolom B dan L
          cell.border = {
            left: { style: "thin" },
          };
        });
      }

      worksheet.mergeCells(`B${rowIndex + 7}:K${rowIndex + 7}`);
      worksheet.getCell(`B${rowIndex + 7}`).fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "000000" },
      };
      worksheet.getCell(`D${rowIndex + 7}`).value = "We Make Every Event Tangible   ";
      worksheet.getCell(`D${rowIndex + 7}`).font = {
        name: "Calibri",
        color: { argb: "FFFFFF" },
        size: 8,
        italic: true,
      };
      worksheet.getCell(`D${rowIndex + 7}`).alignment = { vertical: "middle", horizontal: "right" };

      worksheet.getCell(`B${rowIndex}`).border = {
        top: { style: "thin" },
        left: { style: "thin" },
      };

      // Simpan workbook yang telah diperbarui ke buffer
      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], { type: "application/octet-stream" });

      // Download file Excel
      saveAs(blob, "output.xlsx");
      console.log("Excel file has been generated");
    } catch (error) {
      console.error("Error while filling Excel template", error);
    }
  };

  return (
    <div className="div ml-5 mr-5">
      <div class="columns ml-1">
        <div class="column is-four-fifths has-text-light">
          <h5 className="title is-5">{title}</h5>
          <div class="columns">
            <div class="column is-2">
              <p className="has-text-light">Tanggal dibuat</p>
            </div>
            <div class="column is-3">: {format(new Date(selectedSuratJalan.date), "d MMMM yyyy", { locale: id })}</div>
          </div>
          <div class="columns">
            <div class="column is-2">
              <p>Job No</p>
            </div>
            <div class="column is-3">: {selectedSuratJalan.jobNo}</div>
          </div>
          <div class="columns">
            <div class="column is-2">
              <p>Nama Pengirim</p>
            </div>
            <div class="column is-3">: {selectedSuratJalan.namePengirim}</div>
          </div>
          <div class="columns">
            <div class="column is-2">
              <p>Nama Penerima</p>
            </div>
            <div class="column is-3">: {selectedSuratJalan.namePenerima}</div>
          </div>
          <div class="columns">
            <div class="column is-2">
              <p>Vehicle</p>
            </div>
            <div class="column is-3">: {selectedSuratJalan.vehicle}</div>
          </div>
        </div>

        <div class="column mr-6">
          <button className="button is-normal is-pulled-right" onClick={exportToExcel}>
            <IoPrintOutline />
            &nbsp;&nbsp;&nbsp;Print Surat Jalan
          </button>
        </div>
      </div>
      <br />
      {loading ? (
        <div>Loading...</div> // Atau tambahkan spinner Bulma
      ) : (
        <table className="table is-striped is-fullwidth mr-6">
          <thead>
            <tr>
              <th style={{ width: "50px" }}>No</th>
              <th>Nama Item</th>
              <th className="has-text-centered">QTY</th>
              <th colSpan="2" className="has-text-centered">
                Period
              </th>
            </tr>
          </thead>
          <tbody>
            {suratJalanItems.map((checklistItem, index) => (
              <tr key={checklistItem.id}>
                <td>{index + 1}</td>
                <td>{checklistItem.name}</td>
                <td className="has-text-centered">
                  {checklistItem.quantity} {checklistItem.category}
                </td>
                <td style={{ width: "10rem" }}>{checklistItem.periodTime} Time</td>
                <td style={{ width: "10rem" }}>{checklistItem.periodeDay} Day</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default TabSuratJalanDetail;
