import React from "react";
import { IoPersonCircleOutline } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import { LogOut, reset } from "../features/authSlice";
import logo from "../meetproduction-2-logo.png";

const Navbar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);

  const logout = () => {
    dispatch(LogOut());
    dispatch(reset());
    navigate("/");
  };

  return (
    <div>
      <nav className="navbar is-fixed-top has-shadow" role="navigation" aria-label="main navigation">
        <div className="navbar-brand">
          <NavLink to="/dashboard" className="navbar-item ml-4 p-0">
            <img src={logo} width="135" height="60" alt="logo" />
          </NavLink>

          <a href="!#" role="button" className="navbar-burger burger" aria-label="menu" aria-expanded="false" data-target="navbarBasicExample">
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </a>
        </div>

        <div id="navbarBasicExample" className="navbar-menu">
          <div className="navbar-end">
            <div className="navbar-item mr-5">
              {/* <div className="buttons">
                <button onClick={logout} className="button is-dark">
                  Log out
                </button>
              </div> */}
              <IoPersonCircleOutline style={{ fontSize: "50px" }} />
              {/* <strong>&nbsp;&nbsp;{user.name} |&nbsp;</strong>
              <p className="is-size-7">{user.role}</p> */}
              {user ? (
                <>
                  <strong>&nbsp;&nbsp;{user.name} |&nbsp;</strong>
                  <p className="is-size-7">{user.role}</p>
                </>
              ) : (
                <strong>&nbsp;&nbsp;Guest</strong>
              )}
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
