import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "../custom.css";
import { LoginUser, reset } from "../features/authSlice";
import loginBg from "../loginBg.webp";
import logo from "../meetproduction-2-logo.png";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user, isError, isSuccess, isLoading, message } = useSelector((state) => state.auth);

  useEffect(() => {
    if (user || isSuccess) {
      navigate("/dashboard");
    }
    dispatch(reset());
  }, [user, isSuccess, dispatch, navigate]);

  const Auth = (e) => {
    e.preventDefault();
    dispatch(LoginUser({ email, password }));
  };

  return (
    <section className="hero is-fullheight is-fullwidth">
      <div
        className="hero-body"
        style={{
          backgroundImage: `url(${loginBg})`, // Ganti dengan URL gambar Anda
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="container">
          <div className="columns is-centered">
            <div className="column is-4">
              <img className="mb-5" src={logo} width="200" height="28" alt="logo" style={{ display: "block", margin: "0 auto" }} />
              <form
                onSubmit={Auth}
                className="box"
                style={{
                  backgroundColor: "rgba(10, 10, 10, 0.9)", // Warna putih dengan sedikit transparansi
                  padding: "20px",
                  borderRadius: "8px",
                }}
              >
                {isError && <p className="has-text-centered">{message}</p>}
                <h3 className="title is-3 has-text-centered has-text-white-ter">Dashboard Admin</h3>
                <div className="field">
                  <label className="label has-text-grey-lighter">Email</label>
                  <div className="control">
                    <input type="text" className="input" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email" />
                  </div>
                </div>
                <div className="field has-text-grey-lighter">
                  <label className="label has-text-grey-lighter">Password</label>
                  <div className="control">
                    <input type="password" className="input" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="******" />
                  </div>
                </div>
                <div className="field mt-5">
                  <button type="submit" className="button is-dark is-fullwidth has-text-grey-light">
                    {isLoading ? "Loading..." : "Login"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Login;
